import ApiService from "@/core/services/api.service";
import { responseError } from '@/core/mixin/shared'
import querystring from "querystring";

export const BASE_URL = 'api/data';
export const MODULE_NAME = 'DATA';
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;


export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;

// ACTIONS
export const IMPORT = `IMPORT`;


const state = {
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    }
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
};

const actions = {
    [IMPORT]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then(() => {
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                 context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};